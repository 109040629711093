.App .header {
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .App .header {
    background-image: none !important;
    background-color: #ecf0f3 !important;
    padding-bottom: 0;
  }

  .App .form-contact {
    margin: 20px;
  }

  .img-landing-startup {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (min-width: 768px) {
  .App .header {
    min-height: 56.25vw;
    padding: 20px;
    display: flex;
    align-items: center;
  }

  .img-landing-startup {
    display: none;
  }
}

.form-contact {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  border-radius: 20px;
}

.App form {
  display: block;
}

.App form.form-table {
  background-color: #f6f6f6;
  padding: 40px;
}

header.jumbotron {
  background-color: #e29c15;
  color: #fefefe;
}

header.jumbotron h1 {
  color: #fff;
  font-weight: bold;
}

div.card-info {
  background-color: #eee;
}

div.card-info label {
  color: #000;
}

table.table-app {
  color: #000;
}

table.table-app thead tr th {
  background-color: #EEB548;
  color: #000;
}

.app-icons {
  padding-top: 30px;
}

.img-youtube {
  height: 40px;
  margin-right: 50px;
}

.img-instagram {
  height: 40px;
}

.dollar {
  display: inline-block;
  width: 40px;
}

.form-control-with-dollar {
  display: inline-block;
  width: calc(100% - 40px);
}

.app-section-heading-basic     { color: #000; background-color: #BCE690; }
.app-section-heading-equipment { color: #000; background-color: #9DCFD2; }
.app-section-heading-branding  { color: #000; background-color: #F4D186; }
.app-section-heading-salary    { color: #000; background-color: #EE857E; }
.app-section-heading-service   { color: #000; background-color: #CBBDC8; }

.td-amount {
  background-color: #FCEFD4;
}

#table-item-name {
  min-width: 200px;
}

#table-item-amount {
  min-width: 150px;
}

#table-item-numPeriods {
  min-width: 100px;
}

#table-item-count {
  min-width: 100px;
}

#table-item-subtotal {
  min-width: 150px;
}

#table-item-empty {
  min-width: 50px;
}

#table-item-remarks {
}

#table-tr-total {
  background-color: #F8E0AE;
  font-size: 1.25em;
  font-weight: bold;
  border-top: 2px solid #EDB547;
  border-bottom: 4px solid #EDB547;
  color: #000;
}